import React from "react";
import { useEffect, useState } from "react";
function CountdownTimer(){
   const [expiryTime, setExpiryTime] = useState("28 dec 2022 00:29:29");
   const [countdownTime, setCountdownTime]= useState(
       {
           countdownDays:'',
           countdownHours:'',
           countdownlMinutes:'',
           countdownSeconds:''
       }
   );
    const countdownTimer=()=>{
    
        const timeInterval= setInterval(() => {
          const countdownDateTime = new Date(expiryTime).getTime(); 
          const currentTime = new Date().getTime();
          const remainingDayTime = countdownDateTime - currentTime;
          const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
          const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
          const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);
     
          const runningCountdownTime={
             countdownDays: totalDays,
             countdownHours: totalHours,
             countdownMinutes: totalMinutes,
             countdownSeconds: totalSeconds
          }
       
          setCountdownTime(runningCountdownTime);
     
          if (remainingDayTime < 0) {
             clearInterval(timeInterval);
             setExpiryTime(false);
            }
     
         }, 1000);
    }
     
    useEffect(() => {
        countdownTimer();
    });
   
    return(
   
            <div className="timer">
          
            
            {expiryTime!==false?
                <>
                {countdownTime.countdownDays}  D : {countdownTime.countdownHours} H : {countdownTime.countdownMinutes} M : {countdownTime.countdownSeconds} S
            
                </>
                :<p>Deal has been Expired</p>}
       
        
        </div>
    )
}
export default CountdownTimer;
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import  Box  from "@mui/material/Box";
import { BsArrowRightCircle } from "react-icons/bs";
import { borderRadius } from "@mui/system";
import { db } from "./Firebase";
import {getDoc, doc, setDoc} from "firebase/firestore";
import validator from "validator";
import '../App.css'

export default function MessageModal(props) {
  const { mail, message } = props;
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");

  const sendMail = async() => {
    console.log("mail ----------- ", mail);
    console.log("msg ----------- ", message);
    if (mail === '' || message === ''){
        setText("Please fill the form!");
    } else { 
        if (!validator.isEmail(mail)) {
            setText("Incorrect Email Address!");
        }else{      
            const docRef = doc(db, "appointments", mail);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()){
                await setDoc(docRef, { 
                    'data': [
                        {
                            'mail': mail,
                            'message': message
                        }
                    ],
                  });
            }else{
                let docData = docSnap.data();
                docData.data.push({
                    'mail': mail,
                    'message': message
                });
                await setDoc(docRef, { 
                    'data': [
                        {
                            'mail': mail,
                            'message': message
                        }
                    ],
                });
            }
            setText(' Message sent, get ready to deetox!')
        }
    }
  };
  
  const handleClickOpen = async () => {
    await sendMail();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  // const styles = []

  return (
    <div>
      <Box className='submit-btn'>
      <Button sx={{padding:'15px',width:'150px'}} onClick={handleClickOpen} variant="contained" color="success" size="large">BOOK</Button>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
      
        <DialogContent>
          <DialogContentText  sx={{fontFamily: `'Montserrat Alternates', sans-serif`}}>
            {text}
            </DialogContentText>
        </DialogContent>
    
      </Dialog>
    </div>
  );
}

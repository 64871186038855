import React, { useState } from "react";
import '../App.css';
import Box from "@mui/material/Box";
import MessageModal from "./MessageModal";
import TextField from "@mui/material/TextField";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button, Typography } from "@mui/material";
export default function BookAppointment() {
  const [mail, setMail] = useState('');
  const [message, setMessage] = useState('');

  return (

    <div className="book-profile">
      <><br /></>
      <h5 className="book-title" >
        Book your Appointment now!
      </h5>
      {/* <p className="naman-hos">(Naman Hospital)</p> */}
      <><br /></>
      <Box component="form">
        <TextField sx={{ background: '#1c1b1bb3' }} inputProps={{ style: { color: "white", fontFamily: `'Montserrat Alternates', sans-serif` } }} InputLabelProps={{ style: { fontWeight: 850, fontFamily: `'Montserrat Alternates', sans-serif`, color: "white" } }} color="warning" label='Email' fullWidth id="outlined-basic" className='book-textbox1' onChange={(event) => { setMail(event.target.value) }} />
        <><br /></>
        <><br /></>
        <TextField sx={[{ background: '#1c1b1bb3' }]} inputProps={{ style: { color: "white", fontFamily: `'Montserrat Alternates', sans-serif` } }} label='Message' InputLabelProps={{ style: { fontWeight: 850, fontFamily: `'Montserrat Alternates', sans-serif`, color: "white" } }} multiline rows={4} color="warning" fullWidth id="outlined-basic" className='book-textbox2' onChange={(event) => { setMessage(event.target.value) }} />
      </Box>
      <MessageModal mail={mail} message={message} />
    </div>
  )
}
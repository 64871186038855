import './App.css';
import Bell from './assets/Bell.png';
import Pic from './assets/dandy.png';
import Verified from './assets/check.png';
import FormModal from './components/FormModal';
import { Button, Typography } from '@mui/material';
import BookAppointment from './components/BookAppointment';
import CountdownTimer from './components/CountdownTimer';
import React, { useState } from 'react';



function App() {

  const [username,setUsername]=useState('');
  


  //Read more...
  const [readMore,setReadMore]=useState(false);

  const extraContent=
    <div className='pointer4'>
    <CountdownTimer />
    </div>
  
  const linkName=readMore?'':'Read More in... '


 

  return (
    <div className="App">
      <div className="container">
        <div className="navbar">
          <div className="deetme">
            <h2 className='titlelogo'>deet.me</h2>
          </div>
          
        </div>
        <div className="profile">
          <div className="top_block">
            <img src={Pic} alt="" className='pic_img' />
            <div className='text_block'>
              <p className='creator_name'>Dr. Dandy</p>
              {/* <img src={Verified} alt="" className='pic_verified' /> */}
            </div>
            <p className='mbbs'>M.B.B.S (SMSR, Noida), Creator of Creators.College, Health Hacker (Mind, Pineal, Body)</p>
          </div>
          
        </div>
        
        <div className='social'>
          <p className='social_title'>de-e-register to deetox!</p>
          <div className="inp_box">
            <p className='inp_box_title'>deet.me/</p>
            <input type="text" className='textbox' placeholder='deetname' onChange={(event)=>{setUsername(event.target.value)}}/>
            <FormModal username={username}/>
          </div>

          <div className='date'>
          
            <p className='pointer1'>Dear Deets,</p>
            <p className='pointer2'>Dr. Dheet Dandy does not use social media if it does not pay him. <br/><br/>Deet.me helps you create profiles where you get paid on profile visits and posts.<br/> <br/>Profiles FREE only for deets who register now.</p>
            <br/>
            <div className="readmore">
              <a className="read-more-link" onClick={()=>{setReadMore(!readMore)}}><h2 className='pointer3'>{linkName}</h2></a>
              {readMore && extraContent}
            </div>
          </div>
              <br/>
        </div>
       
        <BookAppointment/>

        <footer className='footer'>
          <p className='footer_subtitle'>Copyright &copy; 2022 deet.me All rights reserved.</p>
        </footer>

        
      </div>
    </div>
  );
}

export default App;
